import { Image } from "@chakra-ui/react";
import angle_down from "../assets/images/icons/angle_down.svg";

import code from "../assets/images/icons/code.svg";
import design from "../assets/images/icons/code.svg";
import engineer from "../assets/images/icons/code.svg";
import idea from "../assets/images/icons/code.svg";
import lab from "../assets/images/icons/lab.svg";
import remote from "../assets/images/icons/remote.svg";
import solutions from "../assets/images/icons/solutions.svg";
import talk from "../assets/images/icons/talk.svg";
import working from "../assets/images/icons/working.svg";
import currently from "../assets/images/icons/currently.svg";
import future from "../assets/images/icons/idea.svg";

export default function CustomIcon({ type, size, style }) {
  if (type === "angle_down") {
    return (
      <Image
        alt="angle down icon"
        src={angle_down}
        boxSize={size}
        style={style}
      />
    );
  }
  if (type === "code") {
    return <Image alt="code icon" src={code} boxSize={size} style={style} />;
  }
  if (type === "design") {
    return (
      <Image alt="design icon" src={design} boxSize={size} style={style} />
    );
  }
  if (type === "engineer") {
    return (
      <Image alt="engineer icon" src={engineer} boxSize={size} style={style} />
    );
  }
  if (type === "idea") {
    return <Image alt="idea icon" src={idea} boxSize={size} style={style} />;
  }
  if (type === "lab") {
    return <Image alt="lab icon" src={lab} boxSize={size} style={style} />;
  }
  if (type === "remote") {
    return (
      <Image alt="remote icon" src={remote} boxSize={size} style={style} />
    );
  }
  if (type === "solutions") {
    return (
      <Image
        alt="solutions icon"
        src={solutions}
        boxSize={size}
        style={style}
      />
    );
  }
  if (type === "talk") {
    return <Image alt="talk icon" src={talk} boxSize={size} style={style} />;
  }
  if (type === "working") {
    return (
      <Image alt="working icon" src={working} boxSize={size} style={style} />
    );
  }
  if (type === "currently") {
    return (
      <Image
        alt="currently icon"
        src={currently}
        boxSize={size}
        style={style}
      />
    );
  }
  if (type === "future") {
    return (
      <Image alt="future icon" src={future} boxSize={size} style={style} />
    );
  }
  if (type) {
    return <>type</>;
  }
  return <>!no-icon-type!</>;
}
