// type 1 = code
// type 2 = graphics

import projectImageCssBlur from "../assets/images/projects/css_blur.jpg";
import projectImageSimpleReader from "../assets/images/projects/simple_reader.png";
import bescrapeImage from "../assets/images/projects/bescrape.png";
import fireflysImage from "../assets/images/projects/fireflys.png";
import eternalStaticImage from "../assets/images/projects/eternal_static.png";
import personalBrandImage from "../assets/images/projects/personal_brand.png";
import storeBrandImage from "../assets/images/projects/store_brand.png";
import linePortImages from "../assets/images/projects/portraits.png";
import lookingGlassImage from "../assets/images/projects/lookingglass.png";
import travelCardsImage from "../assets/images/projects/3dcards.png";
import cyclerImage from "../assets/images/projects/cycler.png";
import inputImage from "../assets/images/projects/inputeffect.png";
import lifecalcImage from "../assets/images/projects/lifecalc.png";

const projects = [
  {
    title: "Simple Reader",
    type: 1,
    image: projectImageSimpleReader,
    link: "https://simple-reader.netlify.com",
    repoLink: "https://github.com/ftairs/simple-reader",
    tools: ["React", "ChakraUI", "Zustand", "NodeJS"],
    desc: "Simple Reader is a demo project that combines data scraping, data compiling and a viewer for that data.",
  },
  {
    title: "Life Calculator Experiment",
    type: 3,
    image: lifecalcImage,
    link: "https://cjjxsx-5173.csb.app/",
    repoLink: "",
    tools: [
      "Javascript",
      "React",
      "ChakraUI",
      "CSS",
      "UI Design",
      "Data Visualization",
    ],
    desc: "This project is part code, part art. Allowing for a sobering look at how much of life has passed.",
  },
  {
    title: "Glassmorphism Background with CSS",
    type: 1,
    image: projectImageCssBlur,
    link: "",
    repoLink: "https://codepen.io/vector8/full/LYawxNg",
    tools: ["CSS", "UI Design"],
    desc: "A UI experiement exploring how to create glassmoorphism with just positioning, overflow and filters.",
  },
  {
    title: "Vanilla JS Text Cycler",
    type: 1,
    image: cyclerImage,
    link: "",
    repoLink: "https://codepen.io/vector8/full/vYMmpjw",
    tools: ["Javascript", "CSS", "Visual Design", "UI Design"],
    desc: "A vanilla JS text cycler that could use any number of words using a text array.",
  },
  {
    title: "Text Input Hover Effect",
    type: 1,
    image: inputImage,
    link: "",
    repoLink: "https://codepen.io/vector8/full/ZEZpjxr",
    tools: ["Javascript", "CSS", "Interaction Effects", "UI Design"],
    desc: "A interaction effect that adds some fun to text inputs.",
  },
  {
    title: "Fireflies",
    type: 3,
    image: fireflysImage,
    link: "",
    repoLink: "https://codepen.io/vector8/full/ZEMVbeL",
    tools: ["Javascript", "Canvas API"],
    desc: "Take a moment to enjoy the peaceful view.",
  },
  {
    title: "Eternal static",
    type: 3,
    image: eternalStaticImage,
    link: "",
    repoLink: "https://codepen.io/vector8/full/NWMZxrL",
    tools: ["Javascript", "Canvas API"],
    desc: "Randomly generated graphic that is half code and half art. Never the same twice.",
  },
  {
    title: "Personal Brand",
    type: 2,
    image: personalBrandImage,
    link: "https://www.behance.net/gallery/193173865/Personal-Brand-Refresh-2024",
    repoLink: "",
    tools: ["Identity Design", "Graphic Design", "Illustrator"],
    desc: "My personal brand for myself. Created in late 2023 and revamped in early 2024.",
  },
  {
    title: "Monoline Portraits 2024",
    type: 2,
    image: linePortImages,
    link: "https://www.behance.net/gallery/193162227/Monoline-Portraits-2024?",
    repoLink: "",
    tools: ["Illustration", "Procreate", "Visual Design"],
    desc: "I regulary make and release these as a for-fun project. Created on Procreate on iPad. A pure labor of fun, art and simplicity.",
  },
  {
    title: "Store Brand",
    type: 2,
    image: storeBrandImage,
    link: "https://www.behance.net/gallery/193173357/Brand-Identity-ABSTRYCT",
    repoLink: "",
    tools: ["Identity Design", "Graphic Design", "Illustrator"],
    desc: "A brand identity for an upcoming webstore featuring geometric designs ranging from a few simple lines to complex, visually lush linework.",
  },

  {
    title: "Looking Glass Experiment",
    type: 1,
    image: lookingGlassImage,
    link: "",
    repoLink: "https://codepen.io/vector8/full/bGJVEMd",
    tools: ["Javascript", "CSS", "Interaction", "UI Design"],
    desc: "A UI feature that allows a use to reveal an image using their mouse position.",
  },
  {
    title: "3D Hover Cards",
    type: 1,
    image: travelCardsImage,
    link: "",
    repoLink: "https://codepen.io/vector8/full/rNoEvyL",
    tools: ["Javascript", "CSS", "Interaction", "UI Design"],
    desc: "A simple 3D effect on hovering of cards. Tunable to have more or less 3d rotation.",
  },
  {
    title: "BeScrape",
    type: 1,
    image: bescrapeImage,
    link: "https://bescrape.netlify.com",
    repoLink: "https://github.com/ftairs/bescrape",
    tools: ["React", "ChakraUI", "Cheerio", "NodeJS", "React-query"],
    desc: "BeScrape is a scraper app that gets data from Behance projects, their metadata and content so that you can extract and save that data. Being remade as a public tool now.",
    wip: true,
  },
];

export default projects;
